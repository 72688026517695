import React, { useState, useEffect, useCallback } from "react";
import Map from "./Map";
import data from "../constants/data";
import CORRECT from "../static/sounds/correct.wav";
import WRONG from "../static/sounds/wrong.mp3";
import Particle from "../objects/Particle";
import Sketch from "react-p5";
import { BsFullscreen } from "react-icons/bs";

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const useAudio = (url) => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    if (playing) {
      audio.play();
    } else {
      audio.pause();
      audio.currentTime = 0;
    }
  }, [audio, playing]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, [audio]);

  return [playing, toggle];
};

const MAX_OPTIONS = 4;

const App = () => {
  const { width, height } = useWindowDimensions();

  const [dataState] = useState(
    Object.assign(
      [],
      data.sort(() => 0.5 - Math.random())
    )
  );
  const [activeDept, setActiveDept] = useState("");
  const [options, setOptions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [selected, setSelected] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [optionalClassName, setOptionalClassName] = useState({});
  const [particles, setParticles] = useState([]);
  const [fullscreen, setFullScreen] = useState(false);
  const [ended, setEnded] = useState(false);

  const [playingCorrect, toggleCorrect] = useAudio(CORRECT);
  const [playingWrong, toggleWrong] = useAudio(WRONG);

  const endGame = useCallback(() => {
    setActiveDept({});
    setEnded(true);
  }, [setActiveDept, setEnded]);

  const setNewDept = useCallback(() => {
    if (dataState.length === 0) {
      endGame();
      return;
    }
    let selected = dataState[getRandomInt(0, dataState.length)];
    const index = dataState.indexOf(selected);
    if (index > -1) dataState.splice(index, 1);
    let selectedOptions = data
      .filter((e) => e.id !== selected.id)
      .slice(0, MAX_OPTIONS - 1);
    selectedOptions.push(selected);
    selectedOptions = selectedOptions.sort(
      (a, b) => a.capital.length - b.capital.length
    );
    setActiveDept(selected);
    setOptions(selectedOptions);
  }, [dataState, setActiveDept, setOptions, endGame]);

  useEffect(() => {
    setTimeout(() => {
      setNewDept();
      setLoaded(true);
    }, 500);
  }, []);

  const testOption = useCallback(
    (e, id) => {
      if (selected) return;
      setSelected(true);
      if (activeDept.id === id) {
        toggleCorrect();
        setAnswers([...answers, { id: activeDept.id, correct: true }]);
      } else {
        toggleWrong();
        setAnswers([...answers, { id: activeDept.id, correct: false }]);
        setOptionalClassName({ id, class: "incorrect-option" });
      }
      setTimeout(() => {
        setOptionalClassName({});
        setSelected(false);
        setNewDept();
        if (playingWrong) toggleWrong();
        if (playingCorrect) toggleCorrect();
      }, 800);
    },
    [
      selected,
      activeDept,
      setSelected,
      answers,
      setAnswers,
      setNewDept,
      setOptionalClassName,
      playingCorrect,
      playingWrong,
      toggleWrong,
      toggleCorrect,
    ]
  );

  const setup = useCallback(
    (p5, canvasParentRef) => {
      p5.createCanvas(width, height).parent(canvasParentRef);
      let particles = [];
      for (let i = 0; i < width / 10; i++) {
        particles.push(new Particle(p5, width, height));
      }
      setParticles(particles);
    },
    [setParticles, width, height]
  );

  const draw = useCallback(
    (p5) => {
      p5.background(0);
      for (let i = 0; i < particles.length; i++) {
        particles[i].createParticle(p5);
        particles[i].moveParticle(p5, width, height);
        particles[i].joinParticles(p5, particles.slice(i), width, height);
      }
    },
    [particles, width, height]
  );

  const handleFullscreen = useCallback(() => {
    if (!fullscreen) {
      const request = document.body.requestFullscreen();
      if (request) setFullScreen(true);
    } else {
      document.exitFullscreen();
      setFullScreen(false);
    }
  }, [fullscreen, setFullScreen]);

  return (
    <div className="root">
      {/* <img src={Image} alt="" className='background' /> */}
      <Sketch setup={setup} draw={draw} className="background" />
      {width / height < 1 ? (
        <div className="container">
          <h1>Por favor, gira el celular.</h1>
        </div>
      ) : (
        <>
          <Map
            data={data}
            key={width}
            active={activeDept.id}
            answers={answers}
          />
          <div className="data-container container">
            <div className="fs-button" onClick={handleFullscreen}>
              <BsFullscreen />
            </div>
            {!ended ? (
              <>
                {height < 500 ? (
                  <></>
                ) : (
                  <div className="score">
                    <span>
                      Correctas:{" "}
                      <b className="correct">
                        {answers.filter((a) => a.correct).length}
                      </b>
                    </span>
                    <span>
                      Incorrectas:{" "}
                      <b className="incorrect">
                        {answers.filter((a) => !a.correct).length}
                      </b>
                    </span>
                  </div>
                )}
                <div
                  className={`pregunta-container ${!activeDept && "loading"}`}
                >
                  <h1>
                    {loaded
                      ? `¿Cúal es la capital de ${activeDept.dept}?`
                      : "Cargando..."}
                  </h1>
                </div>
                <div className="respuestas-container">
                  {loaded &&
                    options.map((e, i) => {
                      return (
                        <div
                          className={`option container2 ${
                            selected && activeDept.id === e.id
                              ? "correct-option"
                              : ""
                          } ${
                            optionalClassName.id === e.id
                              ? optionalClassName.class
                              : ""
                          }`}
                          key={e.capital + i}
                          onClick={(ev) => testOption(ev, e.id)}
                        >
                          <span>{e.capital}</span>
                        </div>
                      );
                    })}
                </div>
                {height < 500 ? (
                  <></>
                ) : (
                  <div className="capcolmil">
                    <h3>Presentado por:</h3>
                    <span>Sebastian Murillo</span>
                  </div>
                )}
              </>
            ) : (
              <>
                <h1>¡El juego ha terminado!</h1>
                <span>
                  Tu puntaje fue:{" "}
                  <b>
                    {Math.round(
                      (answers.filter((a) => a.correct).length * 50) / 31
                    ) / 10}
                    /5.0
                  </b>{" "}
                </span>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default App;
