const data = [
    {'capital': 'Leticia', 'dept': 'Amazonas', 'id': 'amazonas'},
    {'capital': 'Medellín', 'dept': 'Antioquia', 'id': 'antioquia'},
    {'capital': 'Arauca', 'dept': 'Arauca', 'id': 'arauca'},
    {'capital': 'Barranquilla', 'dept': 'Atlántico', 'id': 'atlantico'},
    {'capital': 'Cartagena de Indias', 'dept': 'Bolívar', 'id': 'bolivar'},
    {'capital': 'Tunja', 'dept': 'Boyacá', 'id': 'boyaca'},
    {'capital': 'Manizales', 'dept': 'Caldas', 'id': 'caldas'},
    {'capital': 'Florencia', 'dept': 'Caquetá', 'id': 'caqueta'},
    {'capital': 'Yopal', 'dept': 'Casanare', 'id': 'casanare'},
    {'capital': 'Popayán', 'dept': 'Cauca', 'id': 'cauca'},
    {'capital': 'Valledupar', 'dept': 'Cesar', 'id': 'cesar'},
    {'capital': 'Quibdó', 'dept': 'Chocó', 'id': 'choco'},
    {'capital': 'Montería', 'dept': 'Córdoba', 'id': 'cordoba'},
    {'capital': 'Bogotá', 'dept': 'Cundinamarca', 'id': 'cundinamarca'},
    {'capital': 'Inírida', 'dept': 'Guainía', 'id': 'guainia'},
    {'capital': 'San José del Guaviare', 'dept': 'Guaviare', 'id': 'guaviare'},
    {'capital': 'Neiva', 'dept': 'Huila', 'id': 'huila'},
    {'capital': 'Riohacha', 'dept': 'La Guajira', 'id': 'la_guajira'},
    {'capital': 'Santa Marta', 'dept': 'Magdalena', 'id': 'magdalena'},
    {'capital': 'Villavicencio', 'dept': 'Meta', 'id': 'meta'},
    {'capital': 'Pasto', 'dept': 'Nariño', 'id': 'narino'},
    {'capital': 'San José de Cúcuta',
    'dept': 'Norte de Santander',
    'id': 'norte_de_santander'},
    {'capital': 'Mocoa', 'dept': 'Putumayo', 'id': 'putumayo'},
    {'capital': 'Armenia', 'dept': 'Quindío', 'id': 'quindio'},
    {'capital': 'Pereira', 'dept': 'Risaralda', 'id': 'risaralda'},
    {'capital': 'Bucaramanga', 'dept': 'Santander', 'id': 'santander'},
    {'capital': 'Sincelejo', 'dept': 'Sucre', 'id': 'sucre'},
    {'capital': 'Ibagué', 'dept': 'Tolima', 'id': 'tolima'},
    {'capital': 'Cali', 'dept': 'Valle del Cauca', 'id': 'valle_del_cauca'},
    {'capital': 'Mitú', 'dept': 'Vaupés', 'id': 'vaupes'},
    {'capital': 'Puerto Carreño', 'dept': 'Vichada', 'id': 'vichada'}
]

export default data