import React, { useState, useEffect } from "react";
import { ReactComponent as MapSVG } from "../static/map/map.svg";

const Map = ({ data, active, answers }) => {
  const [dataState, setDataState] = useState([]);

  useEffect(() => {
    const newData = Object.assign([], data);
    newData.forEach((e, i) => {
      newData[i] = { ...e, ref: document.getElementById(e.id) };
    });
    setDataState(newData);
  }, [data]);

  useEffect(() => {
    if (answers.length && dataState.length) {
      answers.forEach((a) => {
        const ref = dataState.find((e) => e.id === a.id).ref;
        ref && ref.classList.add(a.correct ? "map-correct" : "map-incorrect");
      });
    }
  }, [answers, dataState]);

  useEffect(() => {
    if (dataState.length) {
      dataState.forEach((e) => {
        e.ref &&
          e.ref.classList.add(
            e.id === active ? "map-active" : active ? "map-not-active" : null
          );
      });
    }
    return () => {
      if (dataState.length) {
        dataState.forEach((e) => {
          e.ref && e.ref.classList.remove("map-active", "map-not-active");
        });
      }
    };
  }, [dataState, active]);

  return (
    <div className="mapa-container container">
      <MapSVG style={{ width: "100%", height: "100%" }} />
    </div>
  );
};

export default Map;
