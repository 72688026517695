class Particle {
// setting the co-ordinates, radius and the
// speed of a particle in both the co-ordinates axes.
    constructor(p5, width, height){
        this.x = Math.random()*width
        this.y = Math.random()*height
        this.r = Math.random(1,8)*2+5
        this.xSpeed = Math.random(-1,1)
        this.ySpeed = Math.random(-1,1)
    }

    // creation of a particle.
    createParticle(p5) {
        p5.noStroke()
        p5.fill('rgba(255,255,255,0.5)')
        p5.circle(this.x,this.y,this.r)
    }

    // setting the particle in motion.
    moveParticle(p5, width, height) {
        if(this.x < 0 || this.x > width)
            this.xSpeed*=-1
        if(this.y < 0 || this.y > height)
            this.ySpeed*=-1
        this.x+=this.xSpeed
        this.y+=this.ySpeed
    }

    // this function creates the connections(lines)
    // between particles which are less than a certain distance apart
    joinParticles(p5, particles, width, height) {
        particles.forEach(element =>{
            let dis = p5.dist(this.x,this.y,element.x,element.y)
            if(dis<width/20) {
                p5.stroke('rgba(255,255,255,.3)')
                p5.line(this.x,this.y,element.x,element.y)
            }
        })
    }
}

export default Particle